(function(angular) {
    'use strict';

    var ITEMS_CHUNK_SIZE = 10;

    var CAROUSEL_PRODUCT_FILTERS = {
        must: {
            exists: ['family.id', 'family.categoriesPaths.id', 'branch.regularPrice'],
            term: {
                'branch.isActive': true,
                'branch.isVisible': true
            }
        },
        mustNot: {
            term: {
                'branch.regularPrice': 0
            }
        }
    };

    angular.module('mobilezuz').config(['$stateProvider', 'PAGE_ACCESS', function ($stateProvider, PAGE_ACCESS) {
        $stateProvider.state('app.home', {
            url: '/',
            data: {
                stateAccess: PAGE_ACCESS.ALLOW,
                bodyClass: 'home'
            },
            views: {
                '@': {
                    templateUrl: 'views/home.html',
                    controller: 'HomeCtrl as homeCtrl'
                }
            }
        });
    }]).controller('HomeCtrl', [
        '$scope', '$filter', '$rootScope', '$window', '$q', '$state', '$location', '$timeout', 'Cart', 'Tree', 'Products',
        'Offers', 'Orders', 'Retailer', 'Util', 'User', 'SpecialDetailsDialog', 'Api', 'SpHomePageComponents',
        'mDesign', 'PermanentFilters', 'Config', 'HOME_PAGE_CAROUSEL_DISPLAY_TYPES', 'ADVERTISEMENT_AD_TYPE',
        'ADVERTISEMENT_GROUP_TYPE', 'SP_HOME_PAGE_COMPONENT_TYPES', 'BANNER_HEIGHT', 
        function ($scope, $filter, $rootScope, $window, $q, $state, $location, $timeout, Cart, Tree, Products,
                  Offers, Orders, Retailer, Util, User, SpecialDetailsDialog, Api, SpHomePageComponents,
                  mDesign, PermanentFilters, Config, HOME_PAGE_CAROUSEL_DISPLAY_TYPES, ADVERTISEMENT_AD_TYPE,
                  ADVERTISEMENT_GROUP_TYPE, SP_HOME_PAGE_COMPONENT_TYPES, BANNER_HEIGHT) {
            var homeCtrl = this,
                _mainElement = angular.element(document.getElementById('main')),
                _headerElement = angular.element(document.getElementById('header')),
                _mainScrollTop,
                _componentsScroll = SpHomePageComponents.scroll(),
                _listeners = []

                /** @type {boolean} */
                var _isRetailerReplacementsEnabled = null;
            
            $rootScope.title = 'home_page_title';

            // load data in background
            Tree.getTree();

            homeCtrl.isLoggedIn = function () {
                return !!User.getUserLoginData();
            };
            homeCtrl.ordersService = Orders;
            homeCtrl.SpHomePageComponents = SpHomePageComponents;
            homeCtrl.goToCategories = goToCategories;
            homeCtrl.openPromotion = openPromotion;
            homeCtrl.goToPickAndGo = goToPickAndGo;
            homeCtrl.isPriceInRange = isPriceInRange;
            homeCtrl.scroll = scroll;
            homeCtrl.initVideo = initVideo;
            homeCtrl.goToShopLists = goToShopLists;

            homeCtrl.components = [];
            homeCtrl.hasScroll = false;
            homeCtrl.isFinishLoadingConfig = false;

            Config.waitForInit().then(function(){
                

                if(Config.retailer.settings) {
                    var MEDIUM_HOME_PAGE_IMAGE_HEIGHT = 2;
                    var homePageImageHeight = parseInt(Config.retailer.settings.homePageImageHeight || MEDIUM_HOME_PAGE_IMAGE_HEIGHT, 10);
                    homeCtrl.bannerHeight = homePageImageHeight === 1 ? BANNER_HEIGHT.LARGE : homePageImageHeight === 2 ? BANNER_HEIGHT.MEDIUM : BANNER_HEIGHT.SMALL
                    homeCtrl.bannerHeight = homeCtrl.bannerHeight / (6.75 * 3) + "em"

                    _isRetailerReplacementsEnabled =
                      Config.retailer.settings.enablePersonalReplacement;


                }

                homeCtrl.isFinishLoadingConfig = true;

            });

            Retailer.getRetailerSettings().then(function (configuration) {
                homeCtrl.retailer = configuration;
                homeCtrl.backgroundImage = Retailer.backgroundImage;
                var mobileNavbarConfig = Config.retailer.mobileNavbarSettings ? JSON.parse(Config.retailer.mobileNavbarSettings) : {};
                $scope.isEnableNewNavBar = mobileNavbarConfig && mobileNavbarConfig.isEnable ? mobileNavbarConfig.isEnable.appHeader : false;
                $scope.showGradient = $scope.isEnableNewNavBar && mobileNavbarConfig.showGradient;
                homeCtrl.retailer.banners = getBannersToShow(homeCtrl.retailer.banners);

                _addBackwardsCarouselComponent(configuration);
                loadComponents();

                Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/native-promotion',
                    params: {
                        groupType: ADVERTISEMENT_GROUP_TYPE.PLASMA,
                    }
                }).then(function (resp) {
                    if (resp && resp.advertisements && resp.advertisements.plasma && resp.advertisements.plasma.length) {
                        var image = {
                            href: resp.advertisements.plasma[0].url,
                            url: resp.advertisements.plasma[0].mobilePictureUrl,
                            adId: resp.advertisements.plasma[0].adId
                        };
                        if (image.url) {
                            homeCtrl.retailer.banners.splice(1, 0, image);
                        }
                    }
                });
            });

            _listeners.push($scope.$on('vertical-scroll-end', function () {
                loadComponents();
            }));

            function getBannersToShow(retailerBanners) {
                var bannersToShow = [];
                var firstBanner = $filter('orderObjectBy')(retailerBanners, 'sort')[0];
                firstBanner = firstBanner ? [firstBanner] : [];
                angular.forEach(retailerBanners, function(banner) {
                    if (banner.activeStartDate && banner.activeEndDate && _isAvailableBannerShowTime(banner)) {
                        bannersToShow.push(banner);
                    }
                    if (!banner.activeStartDate && !banner.activeEndDate && banner.isActive === true) {
                        bannersToShow.push(banner);
                    }
                });
                return bannersToShow.length ? $filter('orderObjectBy')(bannersToShow, 'sort') : firstBanner;
            }

            function _isAvailableBannerShowTime(banner) {
                var flag = false;
                if (banner.activeStartDate && banner.activeEndDate) {
                    var now = new Date();
                    var activeStartDate = new Date(banner.activeStartDate);
                    var activeEndDate = new Date(banner.activeEndDate);
                    flag = activeStartDate <= now && now < activeEndDate;
                }
                return flag;
            }

            function loadComponents() {
                if (homeCtrl.loadingComponentsPromise) {
                    return homeCtrl.loadingComponentsPromise;
                }

                return homeCtrl.loadingComponentsPromise = _componentsScroll.next().then(function(components) {
                    angular.forEach(components, _addHomePageComponent);
                    _setHasScroll();
                }).finally(function() {
                    delete homeCtrl.loadingComponentsPromise;
                });
            }

            function _addBackwardsCarouselComponent(configuration) {
                if (!configuration.isHomePageSuggestionsActive) {
                    return;
                }

                var component = {
                    names: configuration.homePageSuggestionsDisplayName ? {
                        2: { title: configuration.homePageSuggestionsDisplayName }
                    } : {
                        1: { title: 'מבצעים מובילים' },
                        2: { title: 'Today\'s Special Deals' },
                        3: { title: 'ofertas especiales para hoy' },
                        4: { title: 'Лучшие предложения' },
                        5: { title: 'Meilleures offres' },
						6: { title: 'صفقات اليوم الخاصة' }
                    },
                    items: [{
                        href: $state.href('app.specials'),
                        names: {
                            1: { name: 'לכל המבצעים' },
                            2: { name: 'Go To Specials' },
                            3: { name: 'A todas las ofertas' },
                            4: { name: 'Ко всем скидкам' },
                            5: { name: 'Vers toutes les promotions' },
							6: { name: 'انتقل إلى العروض الخاصة' },
                        }
                    }]
                };
                if (configuration.homePageCarousel === HOME_PAGE_CAROUSEL_DISPLAY_TYPES.PRODUCTS) {
                    component.productsFilters = JSON.stringify({ filters: { must: { term: { isOnHomePageCarousel: true } } } });
                    component.typeId = SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL;
                } else {
                    component.typeId = SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL;
                }

                _addHomePageComponent(component);
            }

            function _addHomePageComponent(component) {
                homeCtrl.components.push(new HomePageComponent(component, _loadOnItemsLessComponent));
            }

            function _loadOnItemsLessComponent(component) {
                _setHasScroll();

                if (!component.hasNoItems) {
                    return;
                }

                // if a component with items does not exist, load more
                if (!homeCtrl.components.find(function(component) {
                    return !component.hasNoItems;
                })) {
                    loadComponents();
                }
            }

            function openPromotion() {
                var userLoginData = User.getUserLoginData();
                if (!userLoginData) {
                    return Util.goToLoginDialog();
                }

                mDesign.dialog({
                    controller: 'SinglePromotion',
                    controllerAs: 'singlePromotion',
                    templateUrl: 'views/templates/single-promotion.html',
                    locals: {
                        promotion: homeCtrl.retailer.promotion
                    }
                });
            }

            function goToCategories($event) {
                $event && $event.preventDefault();
                $state.go('app.categories');
            }

            function goToPickAndGo($event) {
                $event && $event.preventDefault();
                $state.go('app.pickAngGo');
            }

            function goToShopLists() {
                var userId = localStorage.getItem('userId');
                if(!userId) {
                    localStorage.setItem('stateOnHold', 'app.recentPurchases');
                }
            }

            function isPriceInRange(price, min, max) {
                return price >= min && price < (max || Infinity);
            }

            function scroll() {
                _mainElement.scrollTop(0, 400);
            }

            function initVideo() {
                Config.waitForInit().then(function() {
                    if (!homeCtrl.retailer.homePageActiveVideo ||
                        !homeCtrl.retailer.homePageActiveVideo.url) {
                        return;
                    }

                    _loadAMPLibrary().then(function() {
                        homeCtrl.videoIsPlaying = false;
                        var player = amp(
                            //this is needed for dynamic html load do NOT just sent id (rather send element itself)
                            document.getElementById('azuremediaplayer'), {
                                "nativeControlsForTouch": false,
                                autoplay: true,
                                fluid: true,
                                enabled: false
                            });

                        player.ready(function() {
                            this.addEventListener('play', function() {
                                $timeout(function() {
                                    homeCtrl.videoIsPlaying = true;
                                }, 200);
                            });
                        });

                        player.src([
                            {
                                "src": homeCtrl.retailer.homePageActiveVideo.url,
                                "type": "application/vnd.ms-sstr+xml"
                            }
                        ]);
                    });
                });
            }

            function _loadAMPLibrary() {
                return $q.all([
                    _loadTag('script', {
                        src: 'https://d226b0iufwcjmj.cloudfront.net/azure-media-player/azuremediaplayer.min.js',
                        id: 'amp-azure-media-scripts'
                    }),
                    _loadTag('link', {
                        href: 'https://d226b0iufwcjmj.cloudfront.net/azure-media-player/azuremediaplayer.min.css',
                        id: 'amp-azure-media-styles',
                        rel: 'stylesheet'
                    })
                ]);
            }

            function _loadTag(type, attributes) {
                if (attributes.id && document.getElementById(attributes.id)) {
                    return $q.resolve();
                }

                var deferred = $q.defer(),
                    tag = document.createElement(type);

                angular.forEach(attributes, function(value, key) {
                    tag.setAttribute(key, value);
                });

                document.head.appendChild(tag);
                tag.addEventListener('load', deferred.resolve);
                return deferred.promise;
            }

            function _onMainScroll() {
                var currentScrollTop = _mainElement.prop('scrollTop'),
                    withoutScroll = currentScrollTop <= 0;

                if (angular.isUndefined(_mainScrollTop) || withoutScroll !== !_mainScrollTop) {
                    if (withoutScroll) {
                        _headerElement.addClass('has-no-scroll');
                    } else {
                        _headerElement.removeClass('has-no-scroll');
                    }
                }
                if (window.cordova && window.cordova.platformId === 'ios' && !angular.isUndefined(_mainScrollTop) && !withoutScroll) {
                    _headerElement.removeClass('has-no-scroll');
                }
                _mainScrollTop = currentScrollTop;
            }

            function _setHasScroll() {
                $timeout(function() {
                    // at least 100 pixels of extra scroll
                    homeCtrl.hasScroll = (_mainElement.prop('scrollHeight') - _mainElement.prop('offsetHeight')) >= 100;
                }, 500);
            }

            function _bindComponentsCartLines() {
                angular.forEach(homeCtrl.components, function(component) {
                    component.bindProductsCartLines();
                });
            }

            _onMainScroll();
            _setHasScroll();
            _mainElement.bind('scroll', _onMainScroll);
            _listeners.push(function () {
                _mainElement.unbind('scroll', _onMainScroll);
            });

            _listeners.push($rootScope.$on('footer.home.click', function() { scroll(); }));

            _listeners.push($rootScope.$on('cart.lines.add', _bindComponentsCartLines));
            _listeners.push($rootScope.$on('cart.lines.remove', _bindComponentsCartLines));

            Util.destroyListeners($scope, _listeners);

            function HomePageComponent(dbComponent, onload) {
                var self = this,
                    _carouselItemsIndex = 0,
                    _carouselItemsTotal,
                    _carouselItemsPromise,
                    _productsGetter,
                    _productsContext = [];

                self.data = dbComponent;
                self.carouselItems = [];
                self.carouselStyles = _getCarouselStyles();
                self.isLoadingItems = true;

                self.loadCarouselItems = loadCarouselItems;
                self.goToItem = goToItem;
                self.bindProductsCartLines = bindProductsCartLines;
                self.productsContext = Products.productsEditContext(_productsContext);

                _addTitleAsItem();

                if (SpHomePageComponents.isCarouselComponent(self.data)) {
                    loadCarouselItems();
                } else {
                    _finishLoading(self.data && self.data.items && self.data.items.length);
                }

                PermanentFilters.subscribe(_resetCarousel, $scope);

                function loadCarouselItems() {
                    if (_carouselItemsTotal !== undefined && _carouselItemsTotal <= _carouselItemsIndex) {
                        return;
                    }

                    if (_carouselItemsPromise) {
                        return _carouselItemsPromise;
                    }

                    switch(self.data.typeId) {
                        case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
                        case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
                            _carouselItemsPromise = _loadProducts(_carouselItemsIndex);
                            break;
                        case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                            _carouselItemsPromise = _loadSpecials(_carouselItemsIndex);
                            break;
                        default:
                            _carouselItemsPromise = _loadItems(_carouselItemsIndex);
                            break;
                    }

                    return _carouselItemsPromise.then(function(resp) {
                        _carouselItemsIndex += resp.items.length;
                        _carouselItemsTotal = resp.total

                        User.injectSuggestionsForProducts(resp.items);

                        Array.prototype.push.apply(self.carouselItems, resp.items);

                        _finishLoading(_carouselItemsTotal);
                    }).finally(function() {
                        _carouselItemsPromise = undefined;
                    });
                }

                function _getCartLinesArray() {
                    var allLines = [];
                    angular.forEach(Cart.lines, function(line) {
                        allLines.push(line);
                    });
                    return allLines;
                }

                function goToItem($event, item, itemsData) {
                    $event.stopPropagation();
                    if (self.data.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL && !item.item) {
                        SpecialDetailsDialog.show(item.id);
                        // $state.go('app.specials.special', { ssid: item.id });
                    } else {
                        Util.goToProductDialog(item.item || item, null, null, false, itemsData);
                    }
                }

                function bindProductsCartLines() {
                    Cart.getProducts(_productsContext);
                }

                function _getCarouselStyles() {
                    var res = {};

                    switch(self.data.typeId) {
                        case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
                            res.itemWidth = 85;
                            res.items = 1;
                            res.styleClass = { 'large-products-style': true };
                            break;
                        case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
                            res.itemWidth = 47;
                            res.items = 1;
                            res.styleClass = { 'products-style': true };
                            break;
                        case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
                            res.itemWidth = 47;
                            res.items = 1;
                            res.styleClass = { 'specials-style': true };
                            break;
                        default:
                            res.items = 2;
                            res.itemWidth = 47;
                            break;
                    }

                    return res;
                }

                function _finishLoading(total) {
                    if (!total) {
                        self.hasNoItems = true;
                    }

                    if (self.isLoadingItems !== false) {
                        self.isLoadingItems = false;
                        onload(self);
                    }
                }
                
                if (Config.retailer && Config.retailer.settings && Config.retailer.settings.showUnavailableProducts !== 'true') {
                    CAROUSEL_PRODUCT_FILTERS.mustNot.term['branch.isOutOfStock'] = true;
                }
                else {
                    if (Config.retailer && Config.retailer.settings && Config.retailer.settings.isOutOfStockDuration === 'true') {
                        CAROUSEL_PRODUCT_FILTERS.bool = {
                            "should": [
                                {
                                    "bool": {
                                        "must_not": {
                                            "exists": {
                                                "field": "branch.outOfStockShowUntilDate"
                                            }
                                        }
                                    }
                                },
                                {
                                    "bool": {
                                        "must": [
                                            {
                                            "range": {
                                                "branch.outOfStockShowUntilDate": {
                                                "gt": "now"
                                                }
                                            }
                                            },
                                            {
                                            "term": {
                                                "branch.isOutOfStock": true
                                            }
                                            }
                                        ]
                                    }
                                },
                                {
                                    "bool": {
                                        "must": [
                                            {
                                            "term": {
                                                "branch.isOutOfStock": false
                                            }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                }

                function _loadProducts(from) {
                    _productsGetter = _productsGetter || SpHomePageComponents.componentProductsGetter(self.data);

                    return Products.prepareSearchParams({ filters: angular.copy(CAROUSEL_PRODUCT_FILTERS) }).then(function(productsParams) {
                        return _productsGetter.get(from, ITEMS_CHUNK_SIZE, productsParams.filters);
                    }).then(function(resp) {
                        var products = Cart.getProducts(resp.products);
                        Util.setProductIndexPosition(resp.products);
                        return _setProductsCategoryType(products).then(function() {
                            return {
                                items: products,
                                total: resp.total
                            };
                        });
                    });
                }

                function _setProductsCategoryType(products) {
                    var promises = [];

                    angular.forEach(products, function(product) {
                        _productsContext.push(product);
                        promises.push(Util.getCategoryActualTypeByProductData(product).then(function(categoryType) {
                            product.categoryType = categoryType;
                        }));
                    });

                    return $q.all(promises);
                }

                function _loadSpecials(from) {
                    return Offers.prepareSearchParams({
                        from: from,
                        size: ITEMS_CHUNK_SIZE
                    }).then(function(params) {
                        return Offers.getOffers(params);
                    }).then(function(resp) {
                        angular.forEach(resp.specials, function(special) {
                            if (special.item) {
                                special.item = Cart.getProduct(special.item);
                                if (_isRetailerReplacementsEnabled && special.item && special.item.branch && special.item.branch.isOutOfStock == true) {
                                    User.getReplacementSuggestions([special.item.id], []).then(function(data) {
                                        if (data && data.length > 0 && data[0].suggestions) {
                                            special.item._suggestions = data[0].suggestions;
                                        }
                                    })
                                }
                                _productsContext.push(special.item);
                            }
                        });
                        return {
                            items: resp.specials,
                            total: resp.total
                        };
                    });
                }

                function _loadItems() {
                    return $q.resolve({
                        items: self.data && self.data.items,
                        total: self.data && self.data.items && self.data.items.length
                    });
                }

                function _resetCarousel() {
                    if (!SpHomePageComponents.isSingleItemComponent(self.data)) {
                        return;
                    }

                    if (_carouselItemsPromise) {
                        return _carouselItemsPromise.then(_resetCarousel, _resetCarousel);
                    }

                    _carouselItemsTotal = undefined;
                    _carouselItemsIndex = 0;
                    self.carouselItems.splice(0);

                    if (self.hasNoItems) {
                        self.hasNoItems = false;
                        self.isLoadingItems = true;
                    }

                    if (_productsGetter) {
                        _productsGetter.reset();
                    }

                    _addTitleAsItem();
                    return loadCarouselItems();
                }

                function _addTitleAsItem() {
                    if (SpHomePageComponents.isTitleAsItem(self.data)) {
                        self.carouselItems.push({ isComponentTitleItem: true });
                    }
                }
            }
        }]);
})(angular);
