(function (angular) {
  function ctrl(
    $rootScope,
    $window,
    $timeout,
    $q,
    $sce,
    $state,
    Config,
    Retailer,
    User,
    Cart,
    Api,
    HubService,
    SpDialogUrlManager,
    UserVerificationDialog,
    PHONE_TYPES,
    mDesign,
    Util,
    retailerData,
    $location,
    $filter,
    SpCaptcha,
    DataLayer
  ) {
    var loginOrRegisterCtrlV2 = this,
      search = $location.search(),
      _translate = $filter("translate"),
      _masked = $filter("masked");

    if (search.token && search.id) {
      localStorage.removeItem("userLoginData");
      $rootScope.isSSOLoginInProgress = true;
      User.loginWithToken(search.id, search.token).then(function () {});
      $location.search("token", null);
      $location.search("id", null);
      $location.search("loginOrRegister", null);
      $window.history.replaceState(null, null, $location.$$url);
      $window.history.pushState(null, null, $location.$$url);
      if(search.externalReturn && search.externalReturn === 'app.cart') {
        if(search.domain) {
          return $state.go(search.externalReturn, {domain: search.domain});
        } else {
          return $state.go(search.externalReturn);
        }
      }
    }

    angular.extend(loginOrRegisterCtrlV2, {
      otpCodeInputs: [],
      captchaIsInvalid: false,
      user: {
        allowSendPromotions: !!Config.retailer.allowSendPromotionsDefault,
        policyApproval: !!Config.retailer.allowTermsAndConditionsDefault,
      },
      showFooter: true,
      showInvalidOrExpiredText: false,
      showAccountSuspended: false,
      toggleCloseConfirmation: false,
      LoginWithOtpOptions: $rootScope.LOGIN_WITH_OTP_OPTIONS,
      LoginOrRegisterScreens: $rootScope.LOGIN_OR_REGISTER_SCREENS,
      UserTypeForCompletionForm: $rootScope.USER_TYPE_FOR_COMPLETION_FORM,
      otpAttempts: 0,
      retailerData: retailerData,
      backgroundImage: Retailer.backgroundImage,
      config: Config,

      login: login,
      register: register,
      sendOneTimeCode: sendOneTimeCode,
      validateOtpCode: validateOtpCode,
      handleCompletionForm: handleCompletionForm,
      showEnterSmsOrMail: showEnterSmsOrMail,
      goToEnterOtp: goToEnterOtp,
      setFocus: setFocus,
      handlePasteEvent: handlePasteEvent,
      backToLogin: backToLogin,
      filterNonDigits: filterNonDigits,
      filterNonDigitsOnChange: filterNonDigitsOnChange,
      addEventListenersToOtpCodeInputsForBackspaceKey: addEventListenersToOtpCodeInputsForBackspaceKey,
      addAutoFillForOTPFromSMSListener: addAutoFillForOTPFromSMSListener,
      fillOtpCodeInputsFromStringCode: fillOtpCodeInputsFromStringCode,
      getValidLoginScreenBasedOnBackendConfiguration: getValidLoginScreenBasedOnBackendConfiguration,
      changeView: changeView,
      backClose: backClose,
      onSelect: onSelect,
    });

    SpCaptcha.countFormSubmits(loginOrRegisterCtrlV2);

    if (search.registrationEmail) {
      loginOrRegisterCtrlV2.user.email = search.registrationEmail || "";
    }

    _initCtrl();

    //handle chrome autofill and add the missing class
    $timeout(function () {
      var $emailElem = angular.element(document.querySelector('input[type="email"]:-webkit-autofill'));
      if ($emailElem.length) {
        angular
          .element(document.querySelector('input[type="email"]:-webkit-autofill').parentNode.closest("md-input-container"))
          .addClass("md-input-has-value");
        angular
          .element(document.querySelector('input[type="password"]:-webkit-autofill').parentNode.closest("md-input-container"))
          .addClass("md-input-has-value");
      }

      if (loginOrRegisterCtrlV2.isRegistrationTab) {
        document.getElementById("register_tab").click();
      }
    }, 300);

    function changeView(nextView, isGoBack) {
      if (isGoBack) {
        var previousViewState = loginOrRegisterCtrlV2.previousViewState.pop();

        if (previousViewState.view === loginOrRegisterCtrlV2.currentView) return backClose(true);

        if(loginOrRegisterCtrlV2.currentView === loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM) {
          var isLoggedIn = !!User.getUserLoginData();
          if(isLoggedIn) return (loginOrRegisterCtrlV2.toggleCloseConfirmation = true)
        }

        loginOrRegisterCtrlV2.otpPhoneOrEmail = previousViewState.otpPhoneOrEmail;
        loginOrRegisterCtrlV2.currentView = previousViewState.view;
      } else {
        loginOrRegisterCtrlV2.previousViewState.push({
          view: loginOrRegisterCtrlV2.currentView,
          otpPhoneOrEmail: loginOrRegisterCtrlV2.otpPhoneOrEmail,
        });
        loginOrRegisterCtrlV2.currentView = nextView;
      }

      if (loginOrRegisterCtrlV2.currentView === "selectOtpOption") {
        if (
          loginOrRegisterCtrlV2.unifiedAuthSettings &&
          loginOrRegisterCtrlV2.unifiedAuthSettings.loginAndRegistrationWithOTP.shouldUnifyOTPFieldForEmailAndPhone
        ) {
          loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
          loginOrRegisterCtrlV2.previousViewState = [
            { view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: "" },
          ];
        }
      }

      var viewsDontHaveFooter = [
        "enterCodeToValidate",
        "chooseUniqueEmail",
        "enterCompletionForm",
        "forgottenPassword",
        "resetPassword",
        "resetPasswordError",
        "enterFacebookRegistrationForm",
      ];
      if (loginOrRegisterCtrlV2.currentView && viewsDontHaveFooter.includes(loginOrRegisterCtrlV2.currentView))
        loginOrRegisterCtrlV2.showFooter = false;
      else loginOrRegisterCtrlV2.showFooter = true;
    }

    function getValidLoginScreenBasedOnBackendConfiguration() {
      if (Config.retailer.settings.isLoginViaOtpActive === "true" && Config.retailer.settings.numberOfDigitsForOTP) {
        loginOrRegisterCtrlV2.numberOfDigitsForOTPCode = new Array(parseInt(Config.retailer.settings.numberOfDigitsForOTP, 10));
      }
      if (Config.retailer.settings.mainLoginPage === "otp-login" && Config.retailer.settings.isLoginViaOtpActive === "true") {
        if (
          Config.retailer.settings.allowLoginByEmailVerification === "true" &&
          Config.retailer.settings.allowLoginBySmsVerification === "true"
        ) {
          if (
            loginOrRegisterCtrlV2.unifiedAuthSettings &&
            loginOrRegisterCtrlV2.unifiedAuthSettings.loginAndRegistrationWithOTP.shouldUnifyOTPFieldForEmailAndPhone
          ) {
            loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
            loginOrRegisterCtrlV2.previousViewState = [
              {
                view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL,
                otpPhoneOrEmail: "",
              },
            ];
          } else {
            loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.SELECT_OTP_OPTION;
            loginOrRegisterCtrlV2.previousViewState = [
              { view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.SELECT_OTP_OPTION, otpPhoneOrEmail: "" },
            ];
          }
        } else if (Config.retailer.settings.allowLoginByEmailVerification === "true") {
          loginOrRegisterCtrlV2.otpOptionSelected = loginOrRegisterCtrlV2.LoginWithOtpOptions.EMAIL;
          loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
          loginOrRegisterCtrlV2.previousViewState = [
            { view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: "" },
          ];
        } else {
          loginOrRegisterCtrlV2.otpOptionSelected = loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER;
          loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
          loginOrRegisterCtrlV2.previousViewState = [
            { view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: "" },
          ];
        }
      } else {
        loginOrRegisterCtrlV2.currentView = loginOrRegisterCtrlV2.LoginOrRegisterScreens.DEFAULT;
        loginOrRegisterCtrlV2.previousViewState = [
          { view: loginOrRegisterCtrlV2.LoginOrRegisterScreens.DEFAULT, otpPhoneOrEmail: "" },
        ];
      }

      if (
        Config.retailer.settings.allowLoginByEmailVerification !== "true" &&
        Config.retailer.settings.allowLoginBySmsVerification !== "true"
      ) {
        loginOrRegisterCtrlV2.showFooter = false;
      }
    }

    function showEnterSmsOrMail(nextOtpScreenEnum) {
      loginOrRegisterCtrlV2.otpPhoneOrEmail = "";
      loginOrRegisterCtrlV2.otpOptionSelected = nextOtpScreenEnum;
      loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
    }

    function sendOneTimeCode(form) {
      loginOrRegisterCtrlV2.otpPhoneOrEmail = loginOrRegisterCtrlV2.otpPhoneOrEmail && loginOrRegisterCtrlV2.otpPhoneOrEmail.toString();
      loginOrRegisterCtrlV2.otpCodeInputs = [];
      loginOrRegisterCtrlV2.otpOptionSelected = User.getTypeOfOTPFromCredentials(loginOrRegisterCtrlV2.otpPhoneOrEmail);

      if (form && !_validate(form)) return;

      if (loginOrRegisterCtrlV2.otpPhoneOrEmail) {
        var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();

        return User.generateOtpCode(loginOrRegisterCtrlV2.otpPhoneOrEmail, loginOrRegisterCtrlV2.otpOptionSelected, recaptchaHash).then(
          function () {
            loginOrRegisterCtrlV2.showInvalidOrExpiredText = false;

            loginOrRegisterCtrlV2.enterCodeToValidateFormTitle =
              _translate("Verify") +
              " " +
              (loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER
                ? _translate("Phone Number")
                : _translate("Email Address"));
            loginOrRegisterCtrlV2.enterCodeToValidateFormSubtitle = _translate("Please enter the {number of digits} digit code sent to {otp credential} through {type of otp}")
              .replace("{number of digits}", "<strong>" + Config.retailer.settings.numberOfDigitsForOTP + "</strong>")
              .replace("{otp credential}", "<strong style='direction: ltr; display: inline-block;'>" + _masked(loginOrRegisterCtrlV2.otpPhoneOrEmail) + "</strong>")
              .replace(
                "{type of otp}",
                "<strong>" +
                  (loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.EMAIL
                    ? "Email"
                    : "SMS") +
                  "</strong>"
              );
            loginOrRegisterCtrlV2.enterCodeToValidateFormButton = _translate("Continue");

            loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_CODE_TO_VALIDATE);
            $timeout(function () {
              loginOrRegisterCtrlV2.addEventListenersToOtpCodeInputsForBackspaceKey();
              loginOrRegisterCtrlV2.addAutoFillForOTPFromSMSListener();
            }, 500);
          }
        );
      }
    }

    function _validate(form) {
      if (form.$invalid) {
        angular.forEach(form.$error.required, function (field) {
          if (field.$$attr.id === 'captcha_hidden_input') {
            loginOrRegisterCtrlV2.captchaIsInvalid = true
            loginOrRegisterCtrlV2.captchaMessage = _translate('Verification expired. Check the checkbox again')
          }
        })
        return false
      }

      if(loginOrRegisterCtrlV2.currentView === loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_REGISTRATION_FORM) {
        if(!loginOrRegisterCtrlV2.user.policyApproval) {
          mDesign.alert(_translate("Please confirm that you accept the terms and conditions for using this service"))
          return false
        }
        if(!loginOrRegisterCtrlV2.user.byLaws) {
          mDesign.alert(_translate("Please confirm that you accept the terms"))
          return false;
        }
      } else if(loginOrRegisterCtrlV2.currentView === loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM) {
        if(loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.NEW_USER ||
          loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER
        ) {
          if(!loginOrRegisterCtrlV2.user.policyApproval) {
            mDesign.alert(_translate("Please confirm that you accept the terms and conditions for using this service"))
            return false;
          }

          if(loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.NEW_USER) {
            if(!loginOrRegisterCtrlV2.user.byLaws) {
              mDesign.alert(_translate("Please confirm that you accept the terms"))
              return false;
            }
          }
        }

        if(loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER) {
          if (!loginOrRegisterCtrlV2.user.byLaws) {
            mDesign.alert(_translate("Please confirm that you accept the terms"))
            return false;
          }
        }
      }

      return true;
    }

    function addAutoFillForOTPFromSMSListener() {
      if (window.OTPCredential) {
        var otpInputs = document.querySelectorAll(".otp-code-input .input");
        if (otpInputs && otpInputs.length) {
          navigator.credentials
            .get({
              otp: { transport: ["sms"] },
            })
            .then(function (otp) {
              console.log("DEBUG - OTP code received - " + otp.code);
              loginOrRegisterCtrlV2.fillOtpCodeInputsFromStringCode(otp.code);
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      }
    }

    function fillOtpCodeInputsFromStringCode(stringOtpCode) {
      var otpCodeSplitted = stringOtpCode.split("");
      if (otpCodeSplitted.length === loginOrRegisterCtrlV2.numberOfDigitsForOTPCode.length) {
        for (var i = 0; i < otpCodeSplitted.length; i++) {
          loginOrRegisterCtrlV2.otpCodeInputs[i] = otpCodeSplitted[i];
        }
        var validateOtpCodeForm = document.querySelector('form[name="enterCodeToValidate"]');
        loginOrRegisterCtrlV2.validateOtpCode(validateOtpCodeForm);
      } else {
        console.error("Length mismatch in otp code from sms and otp code inputs:" + stringOtpCode);
      }
    }

    function addEventListenersToOtpCodeInputsForBackspaceKey() {
      var otpCodeInputElements = document.querySelectorAll(".otp-code-input .input");
      otpCodeInputElements.forEach(function (inputElement, index) {
        inputElement.addEventListener("keydown", function (event) {
          var key = event.keyCode || event.charCode;
          var isEmpty = inputElement.value === "";
          if (key === 8 && isEmpty) {
            if (index > 0) {
              otpCodeInputElements[index - 1].focus(); // move focus to previous input field
            }
          }
        });
      });
    }

    function goToEnterOtp() {
      loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
      loginOrRegisterCtrlV2.otpPhoneOrEmail = "";
      loginOrRegisterCtrlV2.showAccountSuspended = false;
    }

    function validateOtpCode() {
      var otpCode = loginOrRegisterCtrlV2.verifiedPhoneCredential || loginOrRegisterCtrlV2.otpCodeInputs.join("");
      loginOrRegisterCtrlV2.otpOptionSelected = loginOrRegisterCtrlV2.verifiedPhoneCredential ? loginOrRegisterCtrlV2.LoginWithOtpOptions.VERIFIED_PHONE_CREDENTIAL : loginOrRegisterCtrlV2.otpOptionSelected
      loginOrRegisterCtrlV2.showInvalidOrExpiredText = false;
      loginOrRegisterCtrlV2.shouldDisablePhoneNumberField = false;
      loginOrRegisterCtrlV2.shouldDisableEmailField = false;
      loginOrRegisterCtrlV2.completionDisabledFormFields = [];
      User.setIsTriggerMultiLoyalty(true);
      User.loginWithToken(
        undefined,
        otpCode,
        loginOrRegisterCtrlV2.otpPhoneOrEmail,
        loginOrRegisterCtrlV2.otpOptionSelected,
        undefined,
        loginOrRegisterCtrlV2.selectedUniqueEmail
      )
        .then(function () {
          return User.getUserSettings().then(function (user) {
            if (user && user.isNeedJoinClub) {
              loginOrRegisterCtrlV2.completionFormFields = getAdditionalRegistrationFields();
              loginOrRegisterCtrlV2.userTypeForCompletionForm =
                loginOrRegisterCtrlV2.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER;
              loginOrRegisterCtrlV2.checkboxes = _getCheckboxForCompletionForm();

              if (loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER) {
                loginOrRegisterCtrlV2.completionFormFields = loginOrRegisterCtrlV2.completionFormFields.filter(function (
                  field
                ) {
                  if (field.model === "phoneNumber") return false;
                  return true;
                });
              }

              
              if(user.phones && user.phones.length) {
                user.phones.forEach(function(phone) {
                    if((phone.typeVal === PHONE_TYPES.VERIFY || phone.typeVal === PHONE_TYPES.MOBILE) && phone.phoneNumber) {
                        loginOrRegisterCtrlV2.user.phoneNumber = phone.phoneNumber
                        loginOrRegisterCtrlV2.shouldDisablePhoneNumberField = true
                        return;
                    }
                })
              }

              loginOrRegisterCtrlV2.user.policyApproval = Boolean(user.policyApprovalTime);
              loginOrRegisterCtrlV2.user.allowSendPromotions = user.allowSendPromotions;
              User.setIsTriggerMultiLoyalty(false);
              return changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM);
            }

            loginOrRegisterCtrlV2.otpAttempts = 0;
            DataLayer.push(DataLayer.EVENTS.LOGIN);
            return backClose(true);
          });
        })
        .catch(function (err) {
          User.setIsTriggerMultiLoyalty(false);
          if (err && err.data) {
            if (err.data.error === "User not found") {
              loginOrRegisterCtrlV2.completionFormFields = loginOrRegisterCtrlV2.allRegistrationFields;
              loginOrRegisterCtrlV2.userTypeForCompletionForm = loginOrRegisterCtrlV2.UserTypeForCompletionForm.NEW_USER;
              loginOrRegisterCtrlV2.checkboxes = _getCheckboxForCompletionForm();

              loginOrRegisterCtrlV2.user = {};

              if (loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER) {
                loginOrRegisterCtrlV2.user.phoneNumber = loginOrRegisterCtrlV2.otpPhoneOrEmail;
                loginOrRegisterCtrlV2.shouldDisablePhoneNumberField = true;
              } else if (loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.EMAIL) {
                loginOrRegisterCtrlV2.user.email = loginOrRegisterCtrlV2.otpPhoneOrEmail;
                loginOrRegisterCtrlV2.shouldDisableEmailField = true;
              }

              return loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM);
            }

            if (err.data.error === "Account has been suspended") {
              loginOrRegisterCtrlV2.showAccountSuspended = true;
              return mDesign
                .dialog({
                  focusOnOpen: false,
                  clickOutsideToClose: true,
                  template:
                    "<md-dialog-content>" +
                    '<div style="text-align: center; color: red; font-weight: bold;">' +
                    _translate(
                      loginOrRegisterCtrlV2.showAccountSuspended
                        ? "Sorry, You have exceeded the number of attempts allowed for the current user."
                        : loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER
                        ? "Sorry, we cannot proceed using this phone number"
                        : "Sorry, we cannot proceed using this email"
                    ) +
                    "</div>" +
                    "<br>" +
                    '<p style="text-align: center;">' +
                    _translate(
                      loginOrRegisterCtrlV2.showAccountSuspended
                        ? "Please try again later or contact customer service for assistance: XXX-XXXXXX"
                            .replace(
                              "{otpUserBlockMinutes}",
                              loginOrRegisterCtrlV2.unifiedAuthSettings.loginAndRegistrationWithOTP
                                .durationForMaxOTPAttemptsInMinutes
                            )
                            .replace("XXX-XXXXXX", Config.retailer.contactPhone)
                        : "Please try again or contact customer service for assistance: XXX-XXXXXX".replace(
                            "XXX-XXXXXX",
                            Config.retailer.contactPhone
                          )
                    ) +
                    "</p>" +
                    "</md-dialog-content>" +
                    "<md-dialog-actions>" +
                    '<md-button class="md-primary" ng-click="hide()">{{\'Try again\' | translate}}</md-button>' +
                    "</md-dialog-actions>",
                  controller: [
                    "$scope",
                    function ($scope) {
                      $scope.hide = mDesign.hide;
                    },
                  ],
                })
                .then(function () {
                  //== when user click on warning popup's OK button we scroll down to focus Terms checkbox on bottom
                  _scrollRegisterTabDown();
                });
            }

            if (err.data.error === "userOnlyExistedFromLoyalty") {
              return _initRegisterFormWithLoyaltyMember(
                err.data.errObj && err.data.errObj.loyaltyData
              );
            }

            if (err.data.error === "multipleUsersWithSamePhoneNumber") {
              if(Config.retailer.settings.isSingleEmailSelection === 'true') {
                loginOrRegisterCtrlV2.duplicatedEmails = err.data.errObj;
                loginOrRegisterCtrlV2.verifiedPhoneCredential = err.data.verifiedPhoneCredential;
                return loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.CHOOSE_UNIQUE_EMAIL);
              }
              return mDesign.dialog({
                focusOnOpen: false,
                clickOutsideToClose: true,
                template:
                  "<md-dialog-content>" +
                  '<div style="text-align: center; color: red; font-weight: bold;">' +
                  _translate(
                    loginOrRegisterCtrlV2.showAccountSuspended
                      ? "Sorry, You have exceeded the number of attempts allowed for the current user."
                      : loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER
                      ? "Sorry, we cannot proceed using this phone number"
                      : "Sorry, we cannot proceed using this email"
                  ) +
                  "</div>" +
                  "<br>" +
                  '<p style="text-align: center;">' +
                  _translate(
                    loginOrRegisterCtrlV2.showAccountSuspended
                      ? "Please try again later or contact customer service for assistance: XXX-XXXXXX"
                          .replace(
                            "{otpUserBlockMinutes}",
                            loginOrRegisterCtrlV2.unifiedAuthSettings.loginAndRegistrationWithOTP
                              .durationForMaxOTPAttemptsInMinutes
                          )
                          .replace("XXX-XXXXXX", Config.retailer.contactPhone)
                      : "Please try again or contact customer service for assistance: XXX-XXXXXX".replace(
                          "XXX-XXXXXX",
                          Config.retailer.contactPhone
                        )
                  ) +
                  "</p>" +
                  "</md-dialog-content>" +
                  "<md-dialog-actions>" +
                  '<md-button class="md-primary" ng-click="hide()">{{\'Try again\' | translate}}</md-button>' +
                  "</md-dialog-actions>",
                controller: [
                  "$scope",
                  function ($scope) {
                    $scope.hide = mDesign.hide;
                  },
                ],
              })
            }
          }

          loginOrRegisterCtrlV2.showInvalidOrExpiredText = true;
          loginOrRegisterCtrlV2.otpAttempts += 1;
          if (
            loginOrRegisterCtrlV2.otpAttempts ===
            parseInt(loginOrRegisterCtrlV2.unifiedAuthSettings.loginAndRegistrationWithOTP.maxOTPAttempts || 3, 10)
          ) {
            loginOrRegisterCtrlV2.getValidLoginScreenBasedOnBackendConfiguration();
            loginOrRegisterCtrlV2.otpAttempts = 0;
          }
        })
    }

    /**
     * @typedef {Object} LookupLoyaltyUser
     * @property {string} id
     * @property {string=} clubId
     * @property {Date=} expiration
     * @property {boolean} expired
     * @property {string=} personalId
     * @property {string=} firstName
     * @property {string=} lastName
     * @property {string=} phoneNum
     * @property {string=} email
     * @property {string=} birthDate
     * @property {string=} city
     * @property {string=} street
     * @property {string=} houseNum
     * @property {string=} token
     * @property {number=} points
     * @property {number=} pointsToken
     */

    /**
     * @typedef {Object} RegisField
     * @property {string} label
     * @property {{[key: string]: string}} title
     * @property {string} model
     * @property {string} type
     * @property {boolean} isMandatory
     * @property {boolean} isRequired
     * @property {boolean} isHidden
     * @property {number} minLength
     * @property {number} maxLength
     * @property {string} minLengthErrMessage
     * @property {string} maxLengthErrMessage
     * @property {object} match
     */

    function _initCompleteFormFieldsWithLoyaltyData(
      allRegistrationFields,
      loyaltyMemberData
    ) {
      /** @type {Record<string, keyof LookupLoyaltyUser} */
      var mapRegisWithLoyaltyField = {
        firstName: "firstName",
        lastName: "lastName",
        email: "email",
        phoneNumber: "phoneNum",
        birthDate: "birthDate",
        personalId: "personalId",
        houseNumber: "houseNum",
        city: "city",
        street: "street",
        address1: "",
        address2: "",
        state: "",
        zip: "",
        gender: "",
        membershipApproval: "",
      };

      /** @type {Record<string, boolean>} */
      var hiddenFields = {
        personalId: true,
      };

      var filledUser = {};
      var completionDisabledFormFields = [];

      var completionFormFields = allRegistrationFields.filter(
        /**
         * @param {RegisField} regisField
         */
        function (regisField) {
          if (hiddenFields[regisField.model]) {
            return false;
          }

          /** @type {string} */
          var loyaltyField = mapRegisWithLoyaltyField[regisField.model];

          if (loyaltyMemberData[loyaltyField]) {
            filledUser[regisField.model] = loyaltyMemberData[loyaltyField];
            completionDisabledFormFields.push(regisField.model);
          }

          return true;
        }
      );

      return { completionFormFields: completionFormFields, filledUser: filledUser, completionDisabledFormFields: completionDisabledFormFields };
    }

    /**
   * @param {LookupLoyaltyUser} loyaltyMemberData 
   * @returns {FormCompletionDialog.show}
   */
    function _initRegisterFormWithLoyaltyMember(loyaltyMemberData) {
      loginOrRegisterCtrlV2.userTypeForCompletionForm =
        loginOrRegisterCtrlV2.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER;
      loginOrRegisterCtrlV2.checkboxes = _getCheckboxForCompletionForm();

      var formData = _initCompleteFormFieldsWithLoyaltyData(
        loginOrRegisterCtrlV2.allRegistrationFields,
        loyaltyMemberData || {}
      );

      loginOrRegisterCtrlV2.completionFormFields = formData.completionFormFields;
      loginOrRegisterCtrlV2.user = Object.assign(loginOrRegisterCtrlV2.user, formData.filledUser);
      loginOrRegisterCtrlV2.completionDisabledFormFields = formData.completionDisabledFormFields;

      return loginOrRegisterCtrlV2.changeView(
        loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM, 
        formData.disabledFields
      );
    }

    //  This function is used to not allow user input text characters. <input type="number"/> is not used because it can have leading zeros.
    function filterNonDigits(event) {
      var key = event.key;
      var isDigit = /\d/.test(key);
      var isSpecialKey = [
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "Home",
        "End",
        "ArrowLeft",
        "ArrowUp",
        "ArrowRight",
        "ArrowDown",
      ].includes(key);
      if (
        loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER &&
        !isDigit &&
        !isSpecialKey
      ) {
        event.preventDefault();
      }
    }

    //  This function is used to cover when text is added with pasting.
    function filterNonDigitsOnChange() {
      if (
        loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER &&
        loginOrRegisterCtrlV2.otpPhoneOrEmail
      ) {
        loginOrRegisterCtrlV2.otpPhoneOrEmail = loginOrRegisterCtrlV2.otpPhoneOrEmail.replace(/\D/g, "");
      }
    }

    function setFocus(currentInputIndex) {
      if(loginOrRegisterCtrlV2.showInvalidOrExpiredText) loginOrRegisterCtrlV2.showInvalidOrExpiredText = false;
      if (currentInputIndex < loginOrRegisterCtrlV2.numberOfDigitsForOTPCode.length - 1) {
        $timeout(function () {
          if (loginOrRegisterCtrlV2.otpCodeInputs[currentInputIndex]) {
            document.querySelectorAll(".otp-code-input .input")[currentInputIndex + 1].focus();
          }
        });
      }
    }

    function handlePasteEvent(event) {
      event.preventDefault();
      event.target.blur()
      loginOrRegisterCtrlV2.fillOtpCodeInputsFromStringCode(event.clipboardData.getData("text"));
    }

    function backToLogin() {
      loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.DEFAULT);
      loginOrRegisterCtrlV2.isResetCodeError = false;
      loginOrRegisterCtrlV2.showAccountSuspended = false;
      delete search.resetCode;
      delete search.email;
    }

    function _initCtrl() {
      return $q
        .resolve()
        .then(function () {
          if (!Config.hubRetailer || !Config.hubRetailer.id) {
            return;
          }

          return HubService.getData(Config.hubRetailer.id)
            .then(function (hubData) {
              loginOrRegisterCtrlV2.hubData = hubData;
            })
            .catch(function () {
              //to ignore error and show regular register tabs
            });
        })
        .then(function () {
          loginOrRegisterCtrlV2.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty =
              Config.retailer.loyaltyClubDriver && Config.retailer.loyaltyClubDriver.clientConfig && Config.retailer.loyaltyClubDriver.clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty;

          getUnifiedAuthConfig();
          getValidLoginScreenBasedOnBackendConfiguration();
        });
    }

    function getUnifiedAuthConfig() {
      var selectedClubId = Config.retailer.loyaltyClubDrivers[0].clientConfig.selectedClubForUnifiedAuth;
      var loyaltyClubs = Config.retailer.loyaltyClubDrivers[0].clientConfig.loyaltyClubs;
      var selectedClub;

      if (selectedClubId) {
        selectedClub = loyaltyClubs[selectedClubId];
      } else {
        selectedClub = loyaltyClubs.find(function (obj) {
          return Boolean(obj.unifiedAuth);
        });
      }

      if (selectedClub && selectedClub.unifiedAuth) {
        loginOrRegisterCtrlV2.unifiedAuthSettings = selectedClub.unifiedAuth;

        _transformSelectedFormFields()

        loginOrRegisterCtrlV2.additionalRegistrationFields = getAdditionalRegistrationFields();
        loginOrRegisterCtrlV2.allRegistrationFields = getAllRegistrationFields();
        loginOrRegisterCtrlV2.mandatoryFields = getMandatoryFields();

        loginOrRegisterCtrlV2.loginFormTitle = getText("loginForm", "title", "Log in");
        loginOrRegisterCtrlV2.loginFormTitleColor = getTextColor("loginForm", "titleColor", "#000000");
        loginOrRegisterCtrlV2.loginFormSubtitle = getText("loginForm", "subtitle", "");
        loginOrRegisterCtrlV2.loginFormSubtitleColor = getTextColor("loginForm", "subtitleColor", "#000000");
        loginOrRegisterCtrlV2.loginFormBtnText = getText("loginForm", "btnText", "Log in");
        loginOrRegisterCtrlV2.loginFormBtnTextColor = getTextColor("loginForm", "btnTextColor", "#000000");

        loginOrRegisterCtrlV2.signupFormTitle = getText("signupForm", "title", "New here? Sign up");
        loginOrRegisterCtrlV2.signupFormTitleColor = getTextColor("signupForm", "titleColor", "#000000");
        loginOrRegisterCtrlV2.signupFormSubtitle = getText(
          "signupForm",
          "subtitle",
          "Registering to the website automatically registers you to the club without any cost or obligations"
        );
        loginOrRegisterCtrlV2.signupFormSubtitleColor = getTextColor("signupForm", "subtitleColor", "#000000");
        loginOrRegisterCtrlV2.signupFormBtnText = getText("signupForm", "btnText", "Sign up");
        loginOrRegisterCtrlV2.signupFormBtnTextColor = getTextColor("signupForm", "btnTextColor", "#000000");

        loginOrRegisterCtrlV2.otpFormTitle = getText("otpForm", "title", "Log in or sign up");
        loginOrRegisterCtrlV2.otpFormTitleColor = getTextColor("otpForm", "titleColor", "#000000");
        loginOrRegisterCtrlV2.otpFormSubtitle = getText(
          "otpForm",
          "subtitle",
          "A one - time identification by sending a code to your Mobile or Email"
        );
        loginOrRegisterCtrlV2.otpFormSubtitleColor = getTextColor("otpForm", "subtitleColor", "#000000");
        loginOrRegisterCtrlV2.otpFormBtnText = getText("otpForm", "btnText", "Send code");
        loginOrRegisterCtrlV2.otpFormBtnTextColor = getTextColor("otpForm", "btnTextColor", "#000000");

        loginOrRegisterCtrlV2.completionFormTitle = getText("completionForm", "title", "Few more details and we're done!");
        loginOrRegisterCtrlV2.completionFormTitleColor = getTextColor("completionForm", "titleColor", "#000000");
        loginOrRegisterCtrlV2.completionFormSubtitle = getText(
          "completionForm",
          "subtitle",
          "Registering to the website automatically registers you to the club without any cost or obligations"
        );
        loginOrRegisterCtrlV2.completionFormSubtitleColor = getTextColor("completionForm", "subtitleColor", "#000000");
        loginOrRegisterCtrlV2.completionFormBtxText = getText("completionForm", "btnText", "Continue");
        loginOrRegisterCtrlV2.completionFormBtxTextColor = getTextColor("completionForm", "btnTextColor", "#000000");

        loginOrRegisterCtrlV2.facebookRegistrationFormTitle = getText(
          "facebookRegistrationForm",
          "title",
          "Just one more step to go"
        );
        loginOrRegisterCtrlV2.facebookRegistrationFormTitleColor = getTextColor(
          "facebookRegistrationForm",
          "titleColor",
          "#000000"
        );
        loginOrRegisterCtrlV2.facebookRegistrationFormSubtitle = getText(
          "facebookRegistrationForm",
          "subtitle",
          "Registering to the website automatically registers you to the club without any cost or obligations"
        );
        loginOrRegisterCtrlV2.facebookRegistrationFormSubtitleColor = getTextColor(
          "facebookRegistrationForm",
          "subtitleColor",
          "#000000"
        );
        loginOrRegisterCtrlV2.facebookRegistrationFormBtnText = getText("facebookRegistrationForm", "btnText", "Send code");
        loginOrRegisterCtrlV2.facebookRegistrationFormBtnTextColor = getTextColor(
          "facebookRegistrationForm",
          "btnTextColor",
          "#000000"
        );

        loginOrRegisterCtrlV2.termsAndConditionsText = (
          loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.termsAndConditionsText[Config.language.id] ||
          _translate("I agree to the") + "{Terms & Conditions}" + _translate("and the") + "{Privacy Policy}"
        )
          .replace(
            new RegExp("{Terms & Conditions}", "g"),
            ' <a href="' +
              $state.href("app.terms-and-conditions", { loginOrRegister: null }) +
              '">' +
              _translate("Terms & Conditions") +
              "</a> "
          )
          .replace(
            new RegExp("{Privacy Policy}", "g"),
            ' <a href="' +
              $state.href("app.policies", { loginOrRegister: null }) +
              '">' +
              _translate("Privacy Policy") +
              "</a> "
          );
        loginOrRegisterCtrlV2.promotionText =
          loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.promotionText[Config.language.id] ||
          _translate("Please let me know about promotions");
        loginOrRegisterCtrlV2.loyaltyTermsText = (
          loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.loyaltyTermsText[Config.language.id] ||
          (_translate("loyalty_terms_confirm") + ' ' + '{Loyalty Terms}')
        ).replace(
          new RegExp("{Loyalty Terms}", "g"),
          "<a class='terms-link' ui-sref='app.loyaltyTerms'>" + _translate("Loyalty Terms") + "</a>"
        );
      }

      loginOrRegisterCtrlV2.chooseUniqueEmailTitle =
        (Config.retailer.customSingleEmailAddressesSelectionData &&
          Config.retailer.customSingleEmailAddressesSelectionData[Config.language.id] &&
          Config.retailer.customSingleEmailAddressesSelectionData[Config.language.id].title) ||
        _translate("Choose one Email to continue");
      loginOrRegisterCtrlV2.chooseUniqueEmailSubtitle =
        (Config.retailer.customSingleEmailAddressesSelectionData &&
          Config.retailer.customSingleEmailAddressesSelectionData[Config.language.id] &&
          Config.retailer.customSingleEmailAddressesSelectionData[Config.language.id].subtitle) ||
        _translate(
          "This number is connected with more than one Email. Please choose one of the following email addresses to continue with."
        );
    }

    function getText(form, key, defaultValue) {
      if (
        loginOrRegisterCtrlV2.unifiedAuthSettings &&
        loginOrRegisterCtrlV2.unifiedAuthSettings[form] &&
        loginOrRegisterCtrlV2.unifiedAuthSettings[form][key] &&
        loginOrRegisterCtrlV2.unifiedAuthSettings[form][key][$rootScope.config.language.id]
      ) {
        return loginOrRegisterCtrlV2.unifiedAuthSettings[form][key][$rootScope.config.language.id];
      }
      return _translate(defaultValue);
    }

    function getTextColor(form, key, defaultValue) {
      if (
        loginOrRegisterCtrlV2.unifiedAuthSettings &&
        loginOrRegisterCtrlV2.unifiedAuthSettings[form] &&
        loginOrRegisterCtrlV2.unifiedAuthSettings[form][key]
      ) {
        return loginOrRegisterCtrlV2.unifiedAuthSettings[form][key];
      }
      return defaultValue || "#000000";
    }

    function _transformSelectedFormFields() {
      if (loginOrRegisterCtrlV2.unifiedAuthSettings &&  loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm) {
        loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.selectedRegistrationFormFields = loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.selectedRegistrationFormFields.map(function (field) {
          if (field.model === "phoneNumber") {
            field.inputMode = 'tel'
            field.minLength = 10;
            field.maxLength = 10;
            field.minLengthErrMessage = _translate("Enter valid phone number (10 digits)");
            field.maxLengthErrMessage = _translate("Enter valid phone number (10 digits)");
          } else if(field.model === 'email') {
            field.inputMode = 'email'
          } else if(field.model === 'password') {
            field.minLength = 6;
            field.maxLength = 30;
            field.minLengthErrMessage = _translate('Password must include at least 6 characters');
            field.maxLengthErrMessage = _translate("Password should not be longer than 30 characters");
            field.match = {
              regexp: /^(?=.*[0-9])(?=.*[a-zA-Z]).*$/,
              errorMessage: _translate('Password must contain at least one numeric digit and one character')
            }
          }
          field.label = (field.title && field.title[Config.language.id]) || field.label
          return field
        })
      }
    }

    function getAdditionalRegistrationFields() {
      if (loginOrRegisterCtrlV2.unifiedAuthSettings)
        return loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.selectedRegistrationFormFields
          .filter(function (item) {
            return !item.isMandatory;
          })
      return [];
    }

    function getMandatoryFields() {
      if (loginOrRegisterCtrlV2.unifiedAuthSettings)
        return loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.selectedRegistrationFormFields.filter(function (item) {
          return item.isMandatory;
        });
      return [];
    }

    function getAllRegistrationFields() {
      if (loginOrRegisterCtrlV2.unifiedAuthSettings) return loginOrRegisterCtrlV2.unifiedAuthSettings.signupForm.selectedRegistrationFormFields
      return [];
    }

    function _scrollRegisterTabDown() {
      var registerDialog = document.getElementsByClassName("login-or-register-dialog-v2");
      if (registerDialog && registerDialog[0]) {
        var formElement = registerDialog[0].getElementsByTagName("form");
        if (formElement && formElement[0]) {
          formElement[0].scrollTop = formElement[0].scrollHeight;
        }
      }
    }

    function login() {
      DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {
        data: {
          category: "Button",
          action: "Click",
          label: "Login to site",
        },
      });

      var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
      $rootScope.emailForReActivation = loginOrRegisterCtrlV2.user.email;
      loginOrRegisterCtrlV2.showLoginErr = false;
      User.setIsTriggerMultiLoyalty(true);// prevent reload      User.setIsTriggerMultiLoyalty(true);// prevent reload
      return User.login(loginOrRegisterCtrlV2.user.email, loginOrRegisterCtrlV2.user.password, recaptchaHash)
        .then(function () {
          loginOrRegisterCtrlV2.loggedIn = true;

          return User.getUserSettings().then(function (user) {
            User.setIsTriggerMultiLoyalty(false);
            if (user && user.isNeedJoinClub) {
              loginOrRegisterCtrlV2.completionFormFields = loginOrRegisterCtrlV2.additionalRegistrationFields;
              loginOrRegisterCtrlV2.userTypeForCompletionForm =
                loginOrRegisterCtrlV2.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER;
              loginOrRegisterCtrlV2.checkboxes = _getCheckboxForCompletionForm();

              loginOrRegisterCtrlV2.completionFormFields = loginOrRegisterCtrlV2.completionFormFields.filter(function (field) {
                if (
                  field.model === "phoneNumber" &&
                  loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER &&
                  loginOrRegisterCtrlV2.otpPhoneOrEmail
                )
                  return false;
                return true;
              });

              if(user.phones && user.phones.length) {
                user.phones.forEach(function(phone) {
                    if(phone.typeVal === PHONE_TYPES.VERIFY || phone.typeVal === PHONE_TYPES.MOBILE && phone.phoneNumber) {
                        loginOrRegisterCtrlV2.user.phoneNumber = phone.phoneNumber
                        loginOrRegisterCtrlV2.shouldDisablePhoneNumberField = true
                        return;
                    }
                })
              }

              loginOrRegisterCtrlV2.user.policyApproval = Boolean(user.policyApprovalTime);
              loginOrRegisterCtrlV2.user.allowSendPromotions = user.allowSendPromotions;

              return loginOrRegisterCtrlV2.changeView(loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM);
            }

            $timeout(function () {
              Util.setLiveAlert("you have logged in successfully");
            }, 500);

            return backClose(true);
          });
        })
        .then(function(){
          //restate
          var retState = search.loginDialogRetState;
          if (search.loginDialogRetStateParams) {
            try {
              retParams = JSON.parse(search.loginDialogRetStateParams);
            } catch(e) {}
          }
          if (retState) {
            return $state.go(retState, retParams);
          }

        })
        .catch(function () {
          DataLayer.push(DataLayer.EVENTS.ERROR_LOGIN, {
            data: { email: loginOrRegisterCtrlV2.user.email },
          });
          loginOrRegisterCtrlV2.showLoginErr = true;
          SpCaptcha.formSubmitFailed(loginOrRegisterCtrlV2);
        });
    }

    function register(form) {
      if (!_validate(form)) return;

      DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {
        data: {
          category: "Button",
          action: "Click",
          label: "Register to site",
        },
      });

      var params = angular.copy(loginOrRegisterCtrlV2.user),
        invitePromotion = {
          inviteId: Number(localStorage.getItem("inviteId")),
          promotionId: Number(localStorage.getItem("promotionId")),
        };
      if (invitePromotion.inviteId && invitePromotion.promotionId) {
        params.invitePromotion = invitePromotion;
      }

      if ($rootScope.externalUserCode) {
        params.externalUserCode = $rootScope.externalUserCode;
      }

      params.cartId = Cart.serverCartId || undefined;

      params.recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
      $rootScope.emailForReActivation = loginOrRegisterCtrlV2.user.email;

      return Api.request({
        method: "POST",
        url: "retailers/:rid/users",
        data: params,
      })
        .then(function (registerData) {
          DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {
            data: {
              category: "Button",
              action: "Click",
              label: "Register successful",
            },
          });

          return User.login(loginOrRegisterCtrlV2.user.email, loginOrRegisterCtrlV2.user.password, null).then(function () {
            $rootScope.$emit("customerRegistration", {
              id: registerData.userId,
              firstName: loginOrRegisterCtrlV2.user.firstName,
              lastName: loginOrRegisterCtrlV2.user.lastName,
              email: loginOrRegisterCtrlV2.user.email,
              allowSendPromotions: loginOrRegisterCtrlV2.user.allowSendPromotions,
            });
            localStorage.setItem("isFirstUserLogin", true);
            DataLayer.push(DataLayer.EVENTS.SIGN_UP);
            return registerData;
          });
        })
        .then(function () {
          return SpDialogUrlManager.backClose();
        })
        .then(function (registerData) {
          if (registerData.cartId) {
            // wait for after the login, for the cart call to have a token
            Cart.replaceCart(registerData.cartId, true);
          }
        })
        .then(function () {
          if (User.isVerified()) return true;

          return UserVerificationDialog.show(true);
        })
        .then(function () {
          Util.setLiveAlert("registration completed successfully");
        })
        .then(function() {
          $timeout(function() {
            User.getUserSettings(true);
          }, 150)
        })
        .catch(function (err) {
          DataLayer.push(DataLayer.EVENTS.ERROR_SIGN_UP, {
            data: {
              email: loginOrRegisterCtrlV2.user.email,
              error: err,
            },
          });
          loginOrRegisterCtrlV2.captchaIsInvalid = true;
        });
    }

    function handleCompletionForm(form, event) {
      if (loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.NEW_USER ||
        loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER) {
        return register(form, event)
      } else if (loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER) {
        
        if(!_validate(form)) return;

        return User.getUserSettings().then(function (userData) {
          if (userData) {
            loginOrRegisterCtrlV2.user.firstName = userData.firstName;
            loginOrRegisterCtrlV2.user.lastName = userData.lastName;
            loginOrRegisterCtrlV2.user.phoneNum =
              loginOrRegisterCtrlV2.otpOptionSelected === loginOrRegisterCtrlV2.LoginWithOtpOptions.PHONE_NUMBER
                ? loginOrRegisterCtrlV2.otpPhoneOrEmail
                : loginOrRegisterCtrlV2.user.phoneNumber;

            return Api.request({
              method: "POST",
              url: "/v2/retailers/:rid/users/:uid/loyalty-clubs/_extended-registration",
              data: loginOrRegisterCtrlV2.user,
            }).then(function(){
              SpDialogUrlManager.backClose(true).then(function() {
                // ECOM-14742 need reload so main.js can call autoConnect
                if (Util.isLoyaltyPremiumPackageEnabled()) {
									return Util.reload();
								}

                $timeout(function() {
                  // reload user information after auto join club
                  User.getUserSettings(true)
                }, 250)
              })
            })
          }
        });
      }
    }

    function backClose(forceBack) {
      var stateOnHold = localStorage.getItem("stateOnHold");
      if (stateOnHold) {
        localStorage.setItem("stateOnHold", "");
      }

      if (forceBack || loginOrRegisterCtrlV2.currentView !== loginOrRegisterCtrlV2.LoginOrRegisterScreens.ENTER_COMPLETION_FORM)
        return SpDialogUrlManager.backClose();

      var isLoggedIn = !!User.getUserLoginData();
      if (isLoggedIn) User.logout();

      loginOrRegisterCtrlV2.toggleCloseConfirmation = !loginOrRegisterCtrlV2.toggleCloseConfirmation;
    }

    function _getDefaultCheckboxes() {
      var checkboxes = [
        {
          label:
            loginOrRegisterCtrlV2.termsAndConditionsText ||
            $sce.getTrustedHtml(
              _translate("I agree to the") +
                ' <a href="' +
                $state.href("app.terms-and-conditions", { loginOrRegister: null }) +
                '">' +
                _translate("Terms & Conditions") +
                "</a> " +
                _translate("and the") +
                ' <a href="' +
                $state.href("app.policies", { loginOrRegister: null }) +
                '">' +
                _translate("Privacy Policy") +
                "</a> " +
                _translate("policy_end")
            ),
          model: "policyApproval",
          required: true
        },
        {
          label: loginOrRegisterCtrlV2.promotionText || _translate("Please let me know about promotions"),
          model: "allowSendPromotions",
          required: false
        },
        {
          label:
            loginOrRegisterCtrlV2.loyaltyTermsText ||
            $sce.getTrustedHtml(
              _translate("loyalty_terms_confirm") + ' <a ui-sref="app.loyaltyTerms">' + _translate("Loyalty Terms") + "</a> "
            ),
          model: "byLaws",
          required: true
        },
      ];

      return checkboxes;
    }

    function _getCheckboxForCompletionForm() {
      var checkboxes = [];
      var defaultCheckboxes = _getDefaultCheckboxes();

      if (loginOrRegisterCtrlV2.userTypeForCompletionForm === loginOrRegisterCtrlV2.UserTypeForCompletionForm.NEW_USER) {
        checkboxes = defaultCheckboxes;
      } else if (
        loginOrRegisterCtrlV2.userTypeForCompletionForm ===
        loginOrRegisterCtrlV2.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER
      ) {
        checkboxes = [defaultCheckboxes[2]];
      } else if (
        loginOrRegisterCtrlV2.userTypeForCompletionForm ===
        loginOrRegisterCtrlV2.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER
      ) {
        checkboxes = defaultCheckboxes.slice(0, 2);
      }

      return checkboxes;
    }

    function onSelect(model, opt) {
      loginOrRegisterCtrlV2.selectedOption = opt;
      loginOrRegisterCtrlV2.user[model] = opt.value;
    }

  }

  angular
    .module("mobilezuz")
    .controller("LoginOrRegisterCtrlV2", [
      "$rootScope",
      "$window",
      "$timeout",
      "$q",
      "$sce",
      "$state",
      "Config",
      "Retailer",
      "User",
      "Cart",
      "Api",
      "HubService",
      "SpDialogUrlManager",
      "UserVerificationDialog",
      'PHONE_TYPES',
      "mDesign",
      "Util",
      "retailerData",
      "$location",
      "$filter",
      "SpCaptcha",
      "DataLayer",
      ctrl,
    ]);

  angular.module("mobilezuz").filter("masked", function () {
    return function (string) {
      var firstThreeCharacters = string.slice(0, 3);
      var lastTwoCharacters = string.substr(string.length - 2, 2);
      var starts = "*".repeat(string.length - 5);
      return firstThreeCharacters + starts + lastTwoCharacters;
    };
  });

})(angular);
